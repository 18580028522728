<template>
  <div class="row">
    <div class="telemedicine-management pr-3 pt-3">
      <div class="row pl-3">
        <div class="col-6">
          <div class="d-flex py-1 align-items-center">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 3.33398H9.99996C8.16663 3.33398 6.66663 4.83398 6.66663 6.66732V33.334C6.66663 35.1673 8.16663 36.6673 9.99996 36.6673H30C31.8333 36.6673 33.3333 35.1673 33.3333 33.334V6.66732C33.3333 4.83398 31.8333 3.33398 30 3.33398ZM18.3333 6.66732V15.0007L16.6666 13.7507L15 15.0007V6.66732H18.3333ZM30 33.334H9.99996V6.66732H11.6666V21.6673L16.6666 17.9173L21.6666 21.6673V6.66732H30V33.334ZM18.8 29.934L15.8333 26.3507L11.6666 31.6673H28.3333L22.9666 24.5673L18.8 29.934Z"
                fill="#20419B"
              />
            </svg>
            <span class="txt-pri page-title">Template HSSK</span>
          </div>
        </div>
        <div class="col-6 mb-1">
          <div class="d-flex justify-content-end">
            <button
              class="input-group-text btn btn-l btn-primary"
              @click="showAdd(true)"
            >Thêm mới +</button>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="sort"
            class="p-1 mr-1 form-select px-3 border mx-3"
            @change="getDiseasesTemplates()"
          >
            <option value="updated_at">Ngày cập nhật</option>
            <option value="created_at">Ngày tạo</option>
          </select>
          <select
            name=""
            id=""
            v-model="sort_by"
            class="p-1 form-select px-3 border mx-3"
            @change="getDiseasesTemplates()"
          >
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
          <div class="input-group">
            <input
              class="form-control h-100 border"
              type="text"
              v-model="search"
              @keyup.enter="onSearch()"
              @keyup.delete="onSearchDelete()"
            >
            <button
              class="input-group-text btn btn-light"
              @click="onSearch()"
            >Tìm kiếm</button>
          </div>
        </div>
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Tên hồ sơ</th>
              <th>Thông tin</th>
              <th>Cơ sở y tế</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="temps && temps.data && !loading">
            <tr
              v-for="temp in temps.data"
              :key="temp.id"
            >
              <td data-title="Tên hồ sơ">
                <span class="robo-16-400">{{temp.diseases_name}}</span>
              </td>
              <td data-title="Thông tin">
                <p class="robo-16-400">Chỉ số: {{temp.monitor_stats.length}}</p>
                <p class="robo-16-400">Triệu chứng: {{temp.symptoms.length}}</p>
              </td>
              <td data-title="Cơ sở y tế">
                <span
                  class="robo-16-400"
                  v-if="temp.clinic"
                >{{temp.clinic.name}}</span>
              </td>
              <td>
                <button
                  class="btn bg-pri bd-pri text-white mt-1 mr-2"
                  @click="goToDetail(temp)"
                >Chỉnh sửa</button>
                <button
                  class="btn mt-1"
                  :class="!temp.status ? 'bg-pri bd-pri text-white' : 'btn-white'"
                  @click="changeStatusDiseasesTemplates(temp.id)"
                >{{temp.status ? 'Inactive' : 'Active'}}</button>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="Content"
          v-show="temps && temps.count"
          v-if="temps"
          :items="temps"
          :current_page="current_page"
          @onRefresh="getDiseasesTemplates"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination'
export default {
  name: 'TemplateList',
  components: { Pagination },
  data () {
    return {
      temps: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.getDiseasesTemplates()
  },
  methods: {
    goToDetail (temp) {
      this.$router.push({ path: `/admin/diseases-templates/${temp.id}/show` })
    },
    async getDiseasesTemplates () {
      let self = this
      self.loading = true
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getDiseasesTemplates(params).then(res => {
          if (res && res.data) {
            self.temps = res.data
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.$rf.getRequest('AdminRequest').createDiseasesTemplates(data).then((resp) => {
          self.$router.push(`/admin/diseases-templates/${resp.data.id}/show`)
        })
      }
    },
    async changeStatusDiseasesTemplates (id) {
      let self = this
      if (id) {
        self.$rf.getRequest('AdminRequest').changeStatusDiseasesTemplates(id).then((resp) => {
          self.getDiseasesTemplates()
        })
      }
    },
    onSearch () {
      let self = this
      self.getDiseasesTemplates()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getDiseasesTemplates()
      }
    },
    formatDDMMYYYY (date) {
      return window.moment(date).format('HH:mm DD/MM/YY')
    },
    showAdd (show) {
      this.$router.push({ path: `/admin/diseases-templates-create` })
    }
  }
}
</script>

<style scoped>
</style>