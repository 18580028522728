<template>
    <div style="overflow: hidden;">
         <CmsListTest v-if="type == 1"></CmsListTest>
         <CmsListResults v-if="type == 2"></CmsListResults>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
// import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination.vue'
import CmsListTest from '../../components/Cms/CmsListTest'
import CmsListResults from '../../components/Cms/CmsListResults'
export default {

  name: 'TestInformationDetail',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, CmsListTest, CmsListResults },
  data () {
    return {
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'test',
      title: 'Thông tin sức khoẻ',
      test: '',
      type: ''
    }
  },
  mounted () {
    let self = this
    // self.getPersonTestResult()
    self.type = self.$route.query.type
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    // async getPersonTestResult () {
    //   let self = this
    //   try {
    //      var params = {
    //      }
    //     await self.$rf.getRequest('AdminRequest').getPersonTestResult(params).then(res => {
    //       if (res && res.data) {
    //         self.test = res.data
    //       }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    formatTime (datetime) {
      return window.moment(datetime).format('DD-MM-YYYY')
    },
    gotoDetail (test_result) {
      console.log(test_result)
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>