import { render, staticRenderFns } from "./TemplateDetail.vue?vue&type=template&id=2e6c0cf4&scoped=true"
import script from "./TemplateDetail.vue?vue&type=script&lang=js"
export * from "./TemplateDetail.vue?vue&type=script&lang=js"
import style0 from "./TemplateDetail.vue?vue&type=style&index=0&id=2e6c0cf4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6c0cf4",
  null
  
)

export default component.exports