<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person_disease" :person="person_disease && person_disease.person" :disease="person_disease"></PersonProfile>
        </div>
        <TypeBar v-if="person_disease" :showing="show" :person_id="person_disease.person_id" :disease="person_disease" />
        <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
                <thead class="d-none d-md-table bg--primary-corl">
                    <tr class="">
                        <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl ml-sm-1">Tên xét nghiệm</span></th>
                        <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Ngày tải lên</span></th>
                        <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Người cập nhật</span></th>
                    </tr>
                </thead>
                <tbody class="h-100" style="overflow-x: auto; overflow-y: hidden;">
                    <tr>
                        <td colspan="3">
                            <div class="w-100 mb-2">
                                <div class="row" v-if="test_templates && test_templates.data">
                                    <div class="col-sm-3 mt-3" @click="onShowModalCreateResult(show)" style="cursor: pointer;">
                                        <div class="d-flex justify-content-center align-content-center" style="background: #E7EAF5; box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);border-radius: 20px; height: 102px; margin: 2px;">
                                            <div align="center" class="mt-4">
                                                <div class="robo-20-50" style="color: #20419B;">Chẩn đoán</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="(test_template, index) in test_templates.data" :key="index" class="col-sm-3 mt-3" v-if="test_template.status === 1" @click="onShowCreateTest(test_template.id, test_template.name)" style="cursor: pointer;">
                                        <div class="d-flex justify-content-center align-content-center" style="background: #E7EAF5; box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);border-radius: 20px; height: 102px; margin: 2px;">
                                            <div align="center" class="mt-4">
                                                <div class="robo-20-50" style="color: #20419B;">
                                                    {{test_template && test_template.name}} <span v-if="test_template && test_template.template_count">({{test_template && test_template.template_count}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ModalCreateTest :template_id="template_id" :template_name="template_name" :person_id="person_disease && person_disease.person_id" :template_items="template_items" @onSubmitTests="onSubmitTests"></ModalCreateTest>
        <ModalCreateResult :person_id="person_disease && person_disease.person_id" :template_name="'Chẩn đoán'" @onSubmitResults="onSubmitResults"></ModalCreateResult>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
import appUtils from '../../utils/appUtils'
import ModalCreateTest from '../../components/Cms/ModalCreateTest'
import ModalCreateResult from '../../components/Cms/ModalCreateResult'
export default {

  name: 'TestInformation',
  components: { PersonProfile, HeaderWindow, TypeBar, ModalCreateResult, ModalCreateTest },
  data () {
    return {
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'test',
      title: 'Thông tin sức khoẻ',
      person_disease: null,
      test_templates: null,
      template_id: null,
      template_name: null,
      template_items: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getPersonTestTemplate()
  },
  methods: {
    onClose () {
      let self = this
      self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/detail` }).catch(_ => {})
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getTestTemplatesItems (id) {
      let self = this
      let params = {
        limit: 500,
        test_template_group_id: id
      }
      let resp = await self.$rf.getRequest('AuthRequest').getTestTemplatesItems(params)
      if (resp && resp.data) {
        self.template_items = resp.data.data
        return resp.data
      } else {
        return false
      }
    },
    changeValueTemplatesItems (item) {
      item.forEach(function (element, index) {
        var param = {
          test_name: element.test_name,
          test_value: element.test_value,
          lower_threshold: element.lower_threshold,
          upper_threshold: element.upper_threshold,
          test_unit: element.test_unit,
          error: null
        }
        self.template_items.push(param)
      })
    },
    async getPersonTestTemplate () {
      let self = this
      try {
        await self.$rf.getRequest('AuthRequest').getTestTemplates(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.test_templates = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async onShowCreateTest (id, template_name) {
      let self = this
      self.template_id = id
      self.template_name = template_name
      let resp = await self.getTestTemplatesItems(id)
      self.showModalCreateTest(!!resp)
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    onShowModalCreateResult (show) {
      window.$('#modal-result').modal(show ? 'show' : 'hide')
    },
    showModalCreateTest (is_show) {
      window.$('#modal-create-test').modal(is_show ? 'show' : 'hide')
    },
    async onSubmitTests (person_test_result, person_test_multi) {
      let self = this
      try {
        self.$rf.getRequest('AdminRequest').postPersonTestResult(person_test_result).then(res => {
          if (res && res.data) {
            let id = res.data.id
            self.postPersonTestMulti(id, person_test_multi)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async postPersonTestMulti (id, person_test_multi) {
      let self = this
      try {
        person_test_multi.person_test_result_id = id
        self.$rf.getRequest('AdminRequest').postPersonTestMulti(person_test_multi).then(res => {
          self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/detail` }).catch(_ => {})
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async onSubmitResults (person_test_result, person_exploration_multi) {
      let self = this
      try {
        self.$rf.getRequest('AdminRequest').postPersonTestResult(person_test_result).then(res => {
          if (res && res.data) {
            let id = res.data.id
            self.postPersonExplorationMulti(id, person_exploration_multi)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async postPersonExplorationMulti (id, person_exploration_multi) {
      let self = this
      try {
        person_exploration_multi.person_test_result_id = id
        self.$rf.getRequest('AdminRequest').postPersonExplorationMulti(person_exploration_multi).then(res => {
          self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/detail` }).catch(_ => {})
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>