<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person_disease" :person="person_disease && person_disease.person" :disease="person_disease" :monitor_stats="monitor_stats"></PersonProfile>
        </div>
        <TypeBar v-if="person_disease" :showing="show" :person_id="person_disease.person_id" :disease="person_disease" />
        <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
              <thead class="d-none d-md-table bg--primary-corl">
                <tr class="">
                  <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl ml-sm-1">Tên xét nghiệm</span></th>
                  <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Ngày tải lên</span></th>
                  <th class="robo-20-500" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">Người cập nhật</span></th>
                </tr>
              </thead>
              <tbody v-if="test_results && test_results.data">
              	<tr class="border-bottom mb-2 mt-2" v-for="(test_result, index) in test_results.data" :key="index" style="cursor: pointer;" @click="gotoDetail(test_result)">
              		<td data-title="Tên xét nghiệm">{{test_result && test_result.test_result_name}}</td>
              		<td data-title="Ngày tải lên">{{test_result && test_result.created_at ? formatTime(test_result.created_at) : ''}}</td>
              		<td data-title="Người cập nhật">{{test_result && test_result.created_by && test_result.created_by.name}}</td>
              	</tr>
                <tr v-if="!test_results || !test_results.count">
                  <td colspan="4">
                    <div class="col-sm-12 robo-14-400 text-center">Không có dữ liệu</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination v-show="test_results && test_results.data" :items="test_results" :current_page="current_page" @onRefresh="getPersonTestResult"></Pagination>
        </div>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
// import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination.vue'
export default {

  name: 'TestInformationListing',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'test',
      title: 'Thông tin sức khoẻ',
      person_disease: null,
      test_results: null,
      current_page: 1,
      monitor_stats: null
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
        this.getPersonTestResult()
        this.getStats()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getPersonTestResult()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonTestResult () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var param = {
          person_diseases_id: self.$route?.params?.id,
          person_id: self.person_id,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getPersonTestResult(param).then(res => {
          if (res && res.data) {
            self.test_results = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    },
    formatTime (datetime) {
      return window.moment(datetime).format('DD-MM-YYYY')
    },
    gotoDetail (test_result) {
      console.log(test_result)
      let self = this
      self.$router.push({path: `/admin/test/${self.$route?.params?.id}/detail?test=${test_result.id}&&type=${test_result.type}`})
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>