<template>
  <div class="px-3 px-lg-5">
    <div class="row">
      <div class="col-12 pt-2 pb-3">
        <div class="header-title"><span class="robo-24-500 text-white">Cập nhật template</span></div>
      </div>
      <div class="col-12 mb-3">
        <label
          for=""
          class="robo-20-500 txt-pri"
        >
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
              fill="#20419B"
            />
          </svg>
          Chẩn đoán
        </label>
      </div>
      <div class="col-12 mb-3">
        <label
          for=""
          class="robo-16-500 txt-muted mb-2"
        >Tên bệnh</label>
        <input
          type="text"
          class="form-control bg-blue-gray"
          v-model="form.diseases_name"
        />
        <small
          class="text-red"
          v-if="err_name"
        >* {{err_name}}</small>
      </div>
      <!-- <div class="col-12 mb-3">
        <label
          for=""
          class="robo-16-500 txt-muted mb-2"
        >Diễn biến bệnh</label>
        <textarea
          class="form-control bg-blue-gray"
          placeholder="Diễn biến bệnh như thế nào?"
          v-model="form.diseases_stage"
          style="height: 100px;"
        ></textarea>
      </div> -->
    </div>

    <SettingStats
      :template="template"
      @changeSStats="changeSStats"
      @changeCurrSymp="changeCurrSymp"
      @changeSymp="changeSymp"
    />
    <div class="d-flex justify-content-center align-items-center my-5">
      <button
        class="btn bg-pri bd-pri text-white px-5 radius-0 w-25"
        @click="onSubmit()"
      >Lưu</button>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import SettingStats from '../../../components/Diseases/SettingStats.vue'
const cycle_units = [
  { id: 2, text: 'Ngày' },
  { id: 1, text: 'Giờ' },
  { id: 3, text: 'Tuần' },
  { id: 4, text: 'Tháng' }
]

export default {
  name: 'TemplateCreate',
  components: { vSelect, SettingStats },
  props: [],
  data () {
    return {
      form: {
        diseases_name: '',
        diseases_stage: '',
        note: ''
      },
      err_name: '',
      loading: '',
      cycle_units,
      survival_stats: [],
      curr_survival_stats: [],
      symptoms: [],
      curr_symptoms: [],
      search_stat: '',
      template: null
    }
  },
  watch: {
  },
  mounted () {
    this.getTemplateDetail()
  },
  methods: {
    changeSymp (data) {
      console.log(data)
      this.symptoms = data
    },
    changeCurrSymp (data) {
      this.curr_symptoms = data
    },
    changeSStats (data) {
      this.survival_stats = data
    },
    async getTemplateDetail () {
      let self = this
      await self.$rf.getRequest('AdminRequest').getTemplateDetail(self.$route?.params?.id).then(resp => {
        if (resp && resp.data) {
          self.template = resp.data
          self.form = {
            diseases_name: resp.data.diseases_name,
            diseases_stage: resp.data.diseases_stage,
            note: resp.data.note
          }
        }
      })
    },
    async onSubmit () {
      let self = this
      if (self.loading) return
      if (!self.validateStats() && !self.validateSymptoms()) {
        self.loading = true
        await self.onSave().then(() => {
          self.loading = false
          self.$toast.open({
            message: 'Thành công',
            type: 'success'
          })
          self.$router.push(`/admin/diseases-templates`)
        })
      } else {
        alert('Vui lòng nhập đủ các thông tin yêu cầu!')
      }
    },
    async onSave () {
      let self = this
      await self.onSaveTemplate()
      await self.onSaveStats()
      await self.onSaveSymptoms()
    },
    async onSaveTemplate () {
      let self = this
      if (!self.form.diseases_name) {
        alert('Vui lòng nhập tên bệnh')
        return
      }
      let data = {
        diseases_name: self.form.diseases_name,
        diseases_stage: self.form.diseases_stage,
        note: self.form.note,
        delete_symptoms: 1,
        delete_stats: 1
      }
      let resp = self.$rf.getRequest('AdminRequest').updateDiseasesTemplates(self.$route?.params?.id, data)
      return resp
    },
    async onSaveSymptoms () {
      let self = this
      // add
      if (!self.curr_symptoms.length && !self.symptoms.length) return
      let new_symptoms = []
      self.curr_symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        new_symptoms.push(symp)
      })
      self.symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        symp.person_id = self.person_id
        symp.diseases_templates_id = self.$route?.params?.id
        new_symptoms.push(symp)
      })
      new_symptoms = new_symptoms.filter(item => item.symptom_name !== '' && item.symptom_name !== null)
      if (new_symptoms.length > 0) {
        let params = { symptoms: new_symptoms }
        await self.$rf.getRequest('AdminRequest').multiCreateDiseasesTemplateSymtomps(self.$route?.params?.id, params)
      }
    },
    async onSaveStats () {
      let self = this
      let new_stats = [...self.curr_survival_stats]
      self.survival_stats.map(stat => {
        let new_stat = {
          code: stat.code,
          diseases_templates_id: self.$route?.params?.id,
          min_threshold: parseFloat(stat.min_threshold),
          max_threshold: parseFloat(stat.max_threshold),
          cycle: stat.cycle,
          cycle_unit: stat.cycle_unit,
          unit: stat.unit,
          is_monitor: stat.is_monitor ? 1 : 0
        }
        new_stats.push(new_stat)
      })
      if (new_stats.length > 0) {
        let params = { stats: new_stats }
        await self.$rf.getRequest('AdminRequest').multiCreateDiseasesTemplateMonitorStats(self.$route?.params?.id, params)
      }
    },
    validateStats () {
      let self = this
      return self.survival_stats.filter(stats => stats.is_monitor && stats.stat &&
        (
          (stats.min_threshold === '' || stats.max_threshold === '' || isNaN(stats.min_threshold) || isNaN(stats.max_threshold)) ||
          (parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold)) ||
          (parseFloat(stats.min_threshold) < 0 || parseFloat(stats.max_threshold) < 0) ||
          (stats.cycle === '' || isNaN(stats.cycle))
        )
      ).length
    },
    validateSymptoms () {
      return this.symptoms.filter(symp => !symp.symptom_name).length
    }
  }
}
</script>
<style scoped="true">
.radius-0 {
  border-radius: 0;
}
.header-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20419B;
  color: white;
  height: 64px;
}
</style>